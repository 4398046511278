.hero {
    position: relative;
    background-image: url('/public/hero_image.png');
    background-size: cover;
    background-position: center;
}

.container {
    height: rem(700px);
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-start;
    padding-top: calc(var(--mantine-spacing-xl) * 6);
    padding-bottom: calc(var(--mantine-spacing-xl) * 6);
    z-index: 1;
    position: relative;

    @media (max-width: $mantine-breakpoint-sm) {
        height: rem(500px);
        padding-bottom: calc(var(--mantine-spacing-xl) * 3);
    }
}

.title {
    color: var(--mantine-color-white);
    font-size: 5rem;
    font-weight: 900;
    line-height: 1.1;

    @media (max-width: $mantine-breakpoint-sm) {
        font-size: rem(40px);
        line-height: 1.2;
    }

    @media (max-width: $mantine-breakpoint-xs) {
        font-size: rem(28px);
        line-height: 1.3;
    }
}

.description {
    color: var(--mantine-color-white);
    max-width: rem(600px);

    @media (max-width: $mantine-breakpoint-sm) {
        max-width: 100%;
        font-size: var(--mantine-font-size-sm);
    }
}

.control {
    margin-top: calc(var(--mantine-spacing-xl) * 1.5);

    @media (max-width: $mantine-breakpoint-sm) {
        width: 100%;
    }
}