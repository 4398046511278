.title {
    font-size: rem(34px);
    font-weight: 900;

    @media (max-width: $mantine-breakpoint-sm) {
        font-size: rem(24px);
    }
}

.description {
    max-width: rem(600px);
    margin: auto;

    &::after {
        content: '';
        display: block;
        background-color: var(--mantine-color-blue-filled);
        width: rem(45px);
        height: rem(2px);
        margin-top: var(--mantine-spacing-sm);
        margin-left: auto;
        margin-right: auto;
    }
}

.card {
    border: rem(1px) solid light-dark(var(--mantine-color-gray-1), var(--mantine-color-dark-5));
}


.cardTitle {
    &::after {
        content: '';
        display: block;
        background-color: var(--mantine-color-blue-filled);
        width: rem(45px);
        height: rem(2px);
        margin-top: var(--mantine-spacing-sm);
    }
}

.wrapper {
    min-height: rem(400px);
    /*background-image: linear-gradient(*/
    /*        -90deg,*/
    /*        var(--mantine-color-yellow-4) 0%,*/
    /*        var(--mantine-color-orange-9) 100%*/
    /*);*/
    background-color: var(--mantine-color-gray-8);
    @media (max-width: $mantine-breakpoint-sm) {
        padding: calc(var(--mantine-spacing-xl) * 1.5);
    }
}