.feature {
    position: relative;
    padding-top: calc(var(--mantine-spacing-xl)*1.5);
    padding-left: var(--mantine-spacing-xl);
}

.overlay {
    position: absolute;
    height: rem(100px);
    width: rem(160px);
    top: 0;
    left: 0;
    background-color: var(--mantine-color-yellow-5);
    z-index: 0;
}

.content {
    position: relative;
    z-index: 2;
}

.icon {
    color: var(--mantine-color-blue-filled);
}

.title {
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
}