.wrapper {
    min-height: rem(400px);
    /*background-image: linear-gradient(*/
    /*        -90deg,*/
    /*        var(--mantine-color-yellow-4) 0%,*/
    /*        var(--mantine-color-orange-9) 100%*/
    /*);*/
    background-color: var(--mantine-color-gray-8);
    padding: calc(var(--mantine-spacing-xl) * 2.5);

    @media (max-width: $mantine-breakpoint-sm) {
        padding: calc(var(--mantine-spacing-xl) * 1.5);
    }
}

.title {
    font-family:
            Greycliff CF,
            var(--mantine-font-family);
    color: var(--mantine-color-white);
    line-height: 1;
}

.description {
    color: var(--mantine-color-blue-0);
    max-width: rem(300px);

    @media (max-width: $mantine-breakpoint-sm) {
        max-width: 100%;
    }
}

.form {
    background-color: var(--mantine-color-white);
    padding: var(--mantine-spacing-xl);
    border-radius: var(--mantine-radius-md);
    box-shadow: var(--mantine-shadow-lg);
}

.social {
    color: var(--mantine-color-white);

    @mixin hover {
        color: var(--mantine-color-blue-1);
    }
}

.input {
    background-color: var(--mantine-color-white);
    border-color: var(--mantine-color-gray-4);
    color: var(--mantine-color-black);

    &::placeholder {
        color: var(--mantine-color-gray-5);
    }
}

.inputLabel {
    color: var(--mantine-color-black);
}

.control {
    background-color: var(--mantine-color-blue-6);
}