.wrapper {
    position: relative;
    padding-top: rem(600px);
    padding-bottom: rem(130px);
    background-image: url('/public/hero_2.png');
    background-size: cover;
    background-position: center;

    @media (max-width: $mantine-breakpoint-xs) {
        padding-top: rem(80px);
        padding-bottom: rem(50px);
    }
}

.inner {
    position: relative;
    z-index: 1;
}

.title {
    font-size: rem(40px);
    letter-spacing: rem(-1px);
    padding-left: var(--mantine-spacing-md);
    padding-right: var(--mantine-spacing-md);
    color: var(--mantine-color-white);
    margin-bottom: var(--mantine-spacing-xs);
    text-align: center;
    font-family:
            var(--mantine-font-family);

    @media (max-width: $mantine-breakpoint-xs) {
        font-size: rem(28px);
        text-align: left;
    }
}

.highlight {
    color: var(--mantine-color-yellow-9);
}

.description {
    color: var(--mantine-color-gray-0);
    text-align: center;

    @media (max-width: $mantine-breakpoint-xs) {
        font-size: var(--mantine-font-size-md);
        text-align: left;
    }
}

.controls {
    margin-top: calc(var(--mantine-spacing-xl) * 1.5);
    display: flex;
    justify-content: center;
    padding-left: var(--mantine-spacing-md);
    padding-right: var(--mantine-spacing-md);

    @media (max-width: $mantine-breakpoint-xs) {
        flex-direction: column;
    }
}

.control {
    height: rem(42px);
    font-size: var(--mantine-font-size-md);

    &:not(:first-of-type) {
        margin-left: var(--mantine-spacing-md);
    }

    @media (max-width: $mantine-breakpoint-xs) {
        &:not(:first-of-type) {
            margin-top: var(--mantine-spacing-md);
            margin-left: 0;
        }
    }
}

.secondaryControl {
    color: var(--mantine-color-white);
    background-color: rgba(255, 255, 255, 0.4);

    @mixin hover {
        background-color: rgba(255, 255, 255, 0.45);
    }
}